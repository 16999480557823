export const environment = {
  isDevelopment: false,
  production: true,
  APIEndpoint: 'https://ignite.dev.jo.capella.io/',
  MTECA: {
    GraphQlURL: 'https://mteca-graphql.ignite.dev.jo.capella.io/v1/graphql',
    EndPoint: "https://mteca.dev.jo.capella.io"
  },
  VERSION: require('../../package.json').version,
  auth: {
    API: 'https://mteca-auth.dev.jo.capella.io/',
    logoutURL: '',
    loginURL: 'https://mteca-auth.dev.jo.capella.io/',
    productKey: 'MTECA',
    useProductLogin: true,
    mainAccountId: 14028

  }
};

export const optionListId =
{
  ComponentJobStatusListId: 1,
  ComponentTypeListId: 2
}
