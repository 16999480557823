import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { ILogger, LoggerConfig, LoggerLevel } from "./logger.constants";

@Injectable({
  providedIn: "root",
})
export class LoggerService {
  private _config: ILogger;
  constructor(
    @Inject(LoggerConfig) config: any,
    private _httpClient: HttpClient) {
    this._config = config;
  }

  InfoLogger(LogMessage, ClassName) {
    if (!this._config.disableConsoleLogging) {
      console.info(LogMessage, ClassName);
      if (this._config.serverLogLevel <= LoggerLevel.Info) {
        this.SendLogToCloud("info", LogMessage, ClassName);
      }
    }
  }

  DebugLogger(LogMessage, ClassName) {
    if (!this._config.disableConsoleLogging) {
      console.debug(LogMessage, ClassName);
      if (this._config.serverLogLevel <= LoggerLevel.Debug) {
        this.SendLogToCloud("debug", LogMessage, ClassName);
      }
    }
  }

  WarnLogger(LogMessage, ClassName) {
    if (!this._config.disableConsoleLogging) {
      console.warn(LogMessage, ClassName);
      if (this._config.serverLogLevel <= LoggerLevel.Warn) {
        this.SendLogToCloud("warn", LogMessage, ClassName);
      }
    }
  }

  ErrorLogger(LogMessage, ClassName) {
    if (!this._config.disableConsoleLogging) {
      console.error(LogMessage, ClassName);
      if (this._config.serverLogLevel <= LoggerLevel.Error) {
        this.SendLogToCloud("err", LogMessage, ClassName);
      }
    }
  }

  private SendLogToCloud(LogType, LogMessage, ClassName) {
    console.warn("test send api");
    this._httpClient
      .post(this._config.serverLoggingUrl, {
        LogType: LogType,
        LogMessage: LogMessage,
        ClassName: ClassName,
        AppKey: environment.auth.productKey,
      })
      .toPromise();
  }
}
