import {
    Component, OnInit
} from '@angular/core';
import {
    RequestCacheService
} from '../services/dynamic-reports-services/request-cache.service';

@Component({
    selector: 'reset-cache',
    templateUrl: './reset-cache.component.html',
    styleUrls: ['./reset-cache.component.scss'],


})
export class ResetCacheComponent implements OnInit {
    constructor(
        private _requestCacheService: RequestCacheService
    ) { }
    ngOnInit(): void {
        this._requestCacheService.removeAllCachedReuests();
    }
}
