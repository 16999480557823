import { Component, OnInit } from '@angular/core';
import { AuthService, UserData, UserDataService } from '@discoverer/app-core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel , NavigationError} from '@angular/router';
import { FuseLoadingService } from '@fuse/services/loading';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [UserDataService]
})
export class AppComponent implements OnInit {
  public loggedIn = false;
  public timestamp: string;

  constructor(
    private _userDataService: UserDataService,
    private _authService: AuthService,
    private router: Router,
    private fuseLoadingService: FuseLoadingService
  ) {
    console.log("Hello World");

    this.timestamp = new Date().getTime().toString();


    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart)
        this.fuseLoadingService.show();

      if(event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError)
        this.fuseLoadingService.hide();
    })

    //this._authService.monitorForTokenParamAndFetchUser();
    this._authService.gatewayIdentityUserObservable.subscribe(user => {
      if (!!user) {
        const userData = new UserData();
        userData.name = user.fullName;
        userData.email = user.email;
        userData.id = user.userId;
        userData.token = user.token;
        userData.roles = new Set<string>(user.roles);
        this._userDataService.setUser(userData);
      }
    });
  }

  async ngOnInit() {
    this._authService.gatewayIdentityUserObservable.subscribe(user => {
      if (!!user) {
        const userData = new UserData();
        userData.name = user.fullName;
        userData.email = user.email;
        userData.id = user.userId;
        userData.token = user.token;
        userData.roles = new Set<string>(user.roles);
        this._userDataService.setUser(userData);
      }
    });
  }
}
