
import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { AbstractNavigationItemsService, AuthService, Navigation, UserDataService } from '@discoverer/app-core';
import { FuseNavigationItem } from '@discoverer/fuse/components/navigation';
import { UserData } from '../../../discoverer/app-core/user-data.service';
import { UserRoleEnum } from '../../models/enums/user-role.enum';

export const getNavigationItems = (isUserManagerHidden: boolean): FuseNavigationItem[] => {
    return [
        {
            id: 'projects',
            title: 'Projects',
            type: 'basic',
            icon: 'heroicons_outline:collection',
            link: '/mteca/reports/projects'
        },
        {
            id: 'users',
            title: 'Users',
            type: 'basic',
            hidden: () => { return isUserManagerHidden; },
            icon: 'heroicons_outline:users',
            link: '/mteca/reports/users'
        }
] as FuseNavigationItem[];
}



@Injectable()
export class DashboardService extends AbstractNavigationItemsService {
    loadItems(option: any): Promise<Navigation> {
        throw new Error('Method not implemented.');
    }
    constructor(private userDataService: UserDataService, private _authService: AuthService,) {
        super();
        this._authService.gatewayIdentityUserObservable.subscribe(user => {
            if (!!user) {
                const userData = new UserData();
                userData.name = user.fullName;
                userData.email = user.email;
                userData.id = user.userId;
                userData.token = user.token;
                userData.roles = new Set<string>(user.roles);
                this.userDataService.setUser(userData);
            }
        });
    }
    public override getNavigationItems(): Observable<Navigation> {
        let result = this.userDataService.getUser().then((result) => {

            let isUserManagerHidden = (!result.roles.has(UserRoleEnum.UserManager) && !result.roles.has(UserRoleEnum.Admin));

            return {
                items: getNavigationItems(isUserManagerHidden),
                mainTitle: ""
            };
        }).catch(() => {
            return {
                items: getNavigationItems(true),
                mainTitle: "Material ECA"
            } as Navigation;
        });

        return from(result);
    }

}

