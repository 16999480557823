import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetCacheComponent } from './reset-cache.component';



@NgModule({
  declarations: [ResetCacheComponent],
  imports: [
    CommonModule
  ],
  exports: [ResetCacheComponent]
})
export class ResetCacheModule { }
