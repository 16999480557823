<!-- Loading bar -->



<!-- Navigation -->
<div class="futuristic dark">
    <fuse-vertical-navigation class=" text-white print:hidden" [mode]="isScreenSmall ? 'over' : 'side'"
        [name]="'mainNavigation'" [navigation]="navigation.items" [opened]="!isScreenSmall">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationHeader>

            <!-- Logo -->
            <div class="flex items-center h-20 p-4 pb-0">
                <h1 class="mainTitle">
                    {{navigation.mainTitle}}
                </h1>
                <!-- <img class="w-32" src="assets/images/logo/logo-text-on-dark.png" alt="Logo image"> -->
            </div>
        </ng-container>
        <!-- Navigation footer hook -->
        <ng-container fuseVerticalNavigationFooter>
            <!-- User -->
            <div
                class="w-full mt-0.5 whitespace-nowrap text-sm overflow-ellipsis overflow-hidden leading-normal text-current border-t">
                <reset-cache></reset-cache>
            </div>
            <div class="flex items-center w-full px-2 py-8 border-t">
                <user></user>
                <div class="flex flex-col w-full ml-1 overflow-hidden">
                    <div
                        class="w-full whitespace-nowrap overflow-ellipsis overflow-hidden leading-normal text-current opacity-80">
                        {{user.name}}
                    </div>
                    <div
                        class="w-full mt-0.5 whitespace-nowrap text-sm overflow-ellipsis overflow-hidden leading-normal text-current opacity-50">
                        {{user.email}}
                    </div>
                </div>
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</div>
<ng-container *ngIf="isScreenSmall">
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
        <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
    </button>
</ng-container>

<!-- Wrapper -->
<div class="futuristic flex flex-col flex-auto w-full min-w-0">
    <fuse-loading-bar></fuse-loading-bar>
    <!-- Header -->
    <div *ngIf="false"
        class="relative flex flex-0 items-center w-full h-16 sm:h-15 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">
        <fuse-loading-bar></fuse-loading-bar>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container>
        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <!-- <!--<languages></languages>--> -->
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <search [appearance]="'bar'"></search>
            <!-- <shortcuts></shortcuts>
            <messages></messages>
            <notifications></notifications>
            <button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button> -->
            <user></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div
        class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Capella &copy; {{currentYear}}</span>
    </div> -->

</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
